<template>
  <div>
    <v-chip-group class="hidden-sm-and-down d-print-none" v-model="selectedFilter" column mandatory active-class="accent--text">
      <v-chip filter outlined v-for="(f, i) in items" :key="i">
        {{ f }}
      </v-chip>
    </v-chip-group>
    <div class="hidden-md-and-up d-print-none">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark text v-on="on"> <v-icon left>mdi-arrow-down</v-icon> {{ items[selectedFilter] }}</v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(f, i) in items" :key="i" @click="selectedFilter = i">
            <v-list-item-title :class="selectedFilter == i ? 'lime--text' : ''">
              <v-icon :color="selectedFilter == i ? 'accent' : ''">mdi-arrow-right-bold-hexagon-outline</v-icon> {{ f }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "searchpanel",

  data() {
    return {
      selectedFilter: -1,
    };
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    onChange: {
      type: Function,
    },
    usePreset: {
      type: Boolean,
      default: () => true
    },
    preSelectedFilter: {
      type: Number,
      default: () => -1
    }
  },

  computed: {
    ...mapGetters(["profile", "settings"]),
    hasSlotData: function() {
      return this.$slots.default;
    },
  },

  watch: {
    selectedFilter: function(val) {
      if (this.usePreset) {
        this.$store.dispatch("setSetting", { filter: val });
      }
      this.onChange(val);
    },
  },

  created() {
    this.selectedFilter = this.preSelectedFilter;

    if (this.usePreset && this.profile.keepFiltersOverPages) {
      this.selectedFilter = this.settings.filter;
    }
  },

  methods: {},
};
</script>

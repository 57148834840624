<template>
  <DefaultLayout>
    <template v-slot:header>
      <FilterHeader :items="[$t('dashboard.filter.showAssigned'), $t('dashboard.filter.showAll')]" :onChange="onFilterChange" />
    </template>

    <v-layout v-if="profile.showSearchOnHome">
      <v-flex>
        <SearchHeader :onAction="search" :autoUpdate="true"></SearchHeader>

        <div class="pa-5">
          <div v-if="res.total">
            <small>{{ res.total }} {{ $t("search.teaser.resultCount") }}</small>
          </div>
          <div v-else>{{ $t("search.teaser.noResults") }}</div>
          <v-row>
            <v-col md="6">
              <h2 class="mb-2">{{ $t("common.contentTypes.tasks") }}</h2>
              <SearchResBlock v-if="res.tasks && res.tasks.length" :items="res.tasks" :onAction="goTo" to="/tasks/" v-slot="{ item }">
                <v-icon left color="purple">mdi-calendar-check</v-icon> {{ item.name }}
                <small v-if="item.project && item.project.customer" class="float-right mt-1">{{ item.project.customer.name }}</small>
              </SearchResBlock>
              <v-subheader v-else>{{ $t("common.list.noResults") }}</v-subheader>

              <br style="clear: both" /><br />

              <h2 class="mb-2">{{ $t("common.contentTypes.appointments") }}</h2>
              <SearchResBlock v-if="res.appointments && res.appointments.length" :items="res.appointments" :onAction="goTo" to="/calendars/" v-slot="{ item }">
                <v-icon left color="lime">mdi-calendar</v-icon> {{ item.name }}
                <small v-if="item.project && item.project.customer" class="float-right mt-1">{{ item.project.customer.name }}</small>
              </SearchResBlock>
              <v-subheader v-else>{{ $t("common.list.noResults") }}</v-subheader>

              <template v-if="res.customers && res.customers.length">
                <br style="clear: both" /><br />

                <h2 class="mb-2">{{ $t("common.contentTypes.customers") }}</h2>
                <SearchResBlock :items="res.customers" :onAction="goTo" to="/calendars/" v-slot="{ item }">
                  <v-icon left color="lime">mdi-account-tie</v-icon> {{ item.name }}
                  <small v-if="item.project && item.project.customer" class="float-right mt-1">{{ item.project.customer.name }}</small>
                </SearchResBlock>
              </template>
            </v-col>
            <v-col md="6">
              <h2 class="mb-2">{{ $t("common.contentTypes.projects") }}</h2>
              <template v-if="groupedProjects">
                <div v-for="(prj, i) in groupedProjects" :key="i">
                  <SearchResBlock class="mb-2" :title="i" :items="prj" :onAction="goTo" to="/projects/" v-slot="{ item }">
                    <v-icon left color="primary">mdi-home-account</v-icon> {{ item.name }}
                    <small v-if="item.customer" class="float-right mt-1">{{ item.customer.name }}</small>
                  </SearchResBlock>
                </div>
              </template>
              <v-subheader v-else>{{ $t("common.list.noResults") }}</v-subheader>
            </v-col>
          </v-row>
        </div>
      </v-flex>
    </v-layout>

    <v-container class="text-center" v-else>
      <v-row class="fill-height" align="center" justify="center">
        <template v-for="(item, i) in navData.filter((n) => n.icon)">
          <v-col :key="i" cols="3">
            <v-hover v-slot:default="{ hover }">
              <v-card :elevation="hover ? 12 : 2" class="box" :class="{ 'on-hover': hover }" v-on:click="$router.push(item.targetUrl)">
                <v-card-title class="justify-center bTitle">
                  <v-icon :color="item.color" class="icon">{{ item.icon }}</v-icon>
                  <small class="hidden-sm-and-down">{{ $t(item.tKey) }}</small>
                </v-card-title>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </DefaultLayout>
</template>

<script>
import axios from "axios";
import DefaultLayout from "@/layouts/default.vue";
import SearchResBlock from "@/components/_common/searchresblock";
import SearchHeader from "@/components/_common/searchheader";
import FilterHeader from "@/components/_common/filterheader";

import { mapGetters } from "vuex";
import { formatMixin, formMixin, listMixin } from "@/helpers/mixins";

const navData = require("../router/navdata.json");

export default {
  name: "home",
  mixins: [formatMixin, formMixin, listMixin],
  components: {
    DefaultLayout,
    SearchResBlock,
    SearchHeader,
    FilterHeader,
  },
  data: function () {
    return {
      res: {},
      selectedFilter: -1,
      projectPositions: [],
      projectPositionSelected: [],
      filter: ["", "show=all"],
      navData: navData,
    };
  },
  computed: {
    ...mapGetters(["profile"]),
    groupedProjects() {
      if (!this.res.projects || this.res.projects.length == 0) {
        return null;
      }
      return this.groupBy(this.res.projects);
    },
  },
  watch: {},
  created() {
    this.search("");
  },
  methods: {
    onFilterChange: function (val) {
      this.selectedFilter = val;
      this.search("");
    },
    search: function (query) {
      if (this.selectedFilter == -1) return;
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/common/search?query=${query || ""}&${this.filter[this.selectedFilter] || ""}`)
        .then((res) => {
          this.res = res.data;
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goTo: function (url, item) {
      if (url == "/projects") {
        this.$router.push(`${url}/${item.id}`);
      } else {
        this.$router.push(`${url}`);
      }
    },
    groupBy: function (xs) {
      let l = {};

      xs.forEach((e) => {
        let k = "-"
      
        if (e["projectPhase"] && e["projectPhase"]["name"]) {
          k = e["projectPhase"]["name"];
        }

        if (!l[k]) {
          l[k] = [e];
        } else {
          l[k].push(e);
        }
      });
      return l;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.box {
  height: 250px;

  .bTitle {
    border: 1px solid #cddc39;
    height: 100%;
  }

  .icon {
    font-size: 70px;
  }
}
@media screen and (max-width: 992px) {
  .box {
    height: 150px;
  }
}
@media screen and (max-width: 600px) {
  .box {
    height: 60px;
    .icon {
      font-size: 30px;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('FilterHeader',{attrs:{"items":[_vm.$t('dashboard.filter.showAssigned'), _vm.$t('dashboard.filter.showAll')],"onChange":_vm.onFilterChange}})]},proxy:true}])},[(_vm.profile.showSearchOnHome)?_c('v-layout',[_c('v-flex',[_c('SearchHeader',{attrs:{"onAction":_vm.search,"autoUpdate":true}}),_c('div',{staticClass:"pa-5"},[(_vm.res.total)?_c('div',[_c('small',[_vm._v(_vm._s(_vm.res.total)+" "+_vm._s(_vm.$t("search.teaser.resultCount")))])]):_c('div',[_vm._v(_vm._s(_vm.$t("search.teaser.noResults")))]),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("common.contentTypes.tasks")))]),(_vm.res.tasks && _vm.res.tasks.length)?_c('SearchResBlock',{attrs:{"items":_vm.res.tasks,"onAction":_vm.goTo,"to":"/tasks/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":"purple"}},[_vm._v("mdi-calendar-check")]),_vm._v(" "+_vm._s(item.name)+" "),(item.project && item.project.customer)?_c('small',{staticClass:"float-right mt-1"},[_vm._v(_vm._s(item.project.customer.name))]):_vm._e()]}}],null,false,1677026074)}):_c('v-subheader',[_vm._v(_vm._s(_vm.$t("common.list.noResults")))]),_c('br',{staticStyle:{"clear":"both"}}),_c('br'),_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("common.contentTypes.appointments")))]),(_vm.res.appointments && _vm.res.appointments.length)?_c('SearchResBlock',{attrs:{"items":_vm.res.appointments,"onAction":_vm.goTo,"to":"/calendars/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":"lime"}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(item.name)+" "),(item.project && item.project.customer)?_c('small',{staticClass:"float-right mt-1"},[_vm._v(_vm._s(item.project.customer.name))]):_vm._e()]}}],null,false,3217072146)}):_c('v-subheader',[_vm._v(_vm._s(_vm.$t("common.list.noResults")))]),(_vm.res.customers && _vm.res.customers.length)?[_c('br',{staticStyle:{"clear":"both"}}),_c('br'),_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("common.contentTypes.customers")))]),_c('SearchResBlock',{attrs:{"items":_vm.res.customers,"onAction":_vm.goTo,"to":"/calendars/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":"lime"}},[_vm._v("mdi-account-tie")]),_vm._v(" "+_vm._s(item.name)+" "),(item.project && item.project.customer)?_c('small',{staticClass:"float-right mt-1"},[_vm._v(_vm._s(item.project.customer.name))]):_vm._e()]}}],null,false,4283149012)})]:_vm._e()],2),_c('v-col',{attrs:{"md":"6"}},[_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("common.contentTypes.projects")))]),(_vm.groupedProjects)?_vm._l((_vm.groupedProjects),function(prj,i){return _c('div',{key:i},[_c('SearchResBlock',{staticClass:"mb-2",attrs:{"title":i,"items":prj,"onAction":_vm.goTo,"to":"/projects/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-home-account")]),_vm._v(" "+_vm._s(item.name)+" "),(item.customer)?_c('small',{staticClass:"float-right mt-1"},[_vm._v(_vm._s(item.customer.name))]):_vm._e()]}}],null,true)})],1)}):_c('v-subheader',[_vm._v(_vm._s(_vm.$t("common.list.noResults")))])],2)],1)],1)],1)],1):_c('v-container',{staticClass:"text-center"},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_vm._l((_vm.navData.filter(function (n) { return n.icon; })),function(item,i){return [_c('v-col',{key:i,attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"box",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.$router.push(item.targetUrl)}}},[_c('v-card-title',{staticClass:"justify-center bTitle"},[_c('v-icon',{staticClass:"icon",attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))]),_c('small',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(_vm.$t(item.tKey)))])],1)],1)]}}],null,true)})],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="teaser" v-bind:class="{ dark: isDark }">
    <div v-if="title">{{ title }}</div>
    <div v-for="item in items" :key="item.title" class="ele pointer mhover" @click="onAction(`${to}${item.id}`)">
      <slot :item="item" />
    </div>
  </div>
</template>

<script>
import { isDark } from "@/services/theme";

export default {
  name: "searchblock",
  components: {},
  data: function() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    onAction: {
      type: Function,
    },
    to: {
      type: String,
      default: () => "/",
    },
  },
  computed: {
    isDark: function() {
      return isDark();
    },
    hasSlotData: function() {
      return this.$slots.default;
    },
  },
};
</script>

<style lang="scss" scoped>
.teaser {
  clear: both;

  .ele {
    border-radius: 5px;
    //border: 1px solid #aaaaaa;
    //background-color: white;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
  }

  &.dark {
    .ele {
      //border: 1px solid #666666;
      //background-color: #333333;
    }
  }
}
</style>
